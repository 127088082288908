<template>
  <Layout>
    <div class="row align-items-center text-center page-headline mb-3">
      <div class="col-md-6 text-md-left">
        <h1 class="display-3">{{ $t("page.company.cafes") }}</h1>
      </div>

      <div class="col-md-6 text-md-right">
        <router-link :to="`/company/cafes/create`" class="btn btn-primary"
          ><span class="material-symbols-rounded mr-2"> add </span>
          {{ $t("btn.add-cafes") }}</router-link
        >
      </div>
    </div>

    <div v-if="loading">
      <div class="text-center">
        <b-spinner variant="primary" />
      </div>
    </div>
    <div v-else>
      <b-alert
        v-if="error"
        variant="danger"
        class="mt-3"
        dismissible
        :show="Boolean(error)"
        >{{ error }}</b-alert
      >
      <template v-else>
        <div class="card">
          <div class="card-body">
            <div class="search-field mb-2">
              <b-form-input
                v-model="search"
                :placeholder="`${$t('btn.search')}...`"
                class="search-field-input"
                @keyup.enter="searchStart"
              />
              <button
                v-if="search.length >= 3"
                class="search-field-btn search-field-btn-reset"
                @click="searchReset"
                :title="$t('btn.reset')"
              >
                <span class="material-symbols-rounded search-field-ico">
                  close
                </span>
              </button>
              <button
                class="search-field-btn"
                @click="searchStart"
                :title="$t('btn.search')"
              >
                <span class="material-symbols-rounded search-field-ico">
                  search
                </span>
              </button>
            </div>

            <div class="row mb-md-2 text-center table-filters">
              <div class="col-sm-12 col-md-auto ml-auto mt-2 mt-md-0">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    <span class="text-nowrap">{{ $t("form.quantity") }}:</span>
                    <b-form-select
                      v-model="per_page"
                      size="sm"
                      :options="pageOptions"
                      class="form-control form-control-sm ml-2"
                    ></b-form-select>
                  </label>
                </div>
              </div>
            </div>

            <div class="table-responsive mb-0">
              <b-table
                responsive="sm"
                :items="tableData"
                :fields="tableFields"
                :per-page="per_page"
                :busy.sync="isBusy"
                show-empty
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                :no-local-sorting="true"
                @head-clicked="headClicked"
              >
                <template #head(select)="data">
                  <b-form-checkbox v-model="selectedAll" />
                </template>
                <template #head(picture)="data">
                  {{ $t("table.photo") }}
                </template>
                <template #head(address)="data">
                  {{ $t("table.address") }}
                </template>
                <template #head(action)="data">
                  <a
                    v-if="selected?.length"
                    href="#"
                    @click.prevent="confirmDeleteArr()"
                    class="action-delete"
                    :title="$t('btn.remove')"
                    ><span class="material-symbols-rounded fz-28 v-middle mx-1">
                      delete_forever
                    </span></a
                  >
                </template>

                <template #cell(select)="data">
                  <b-form-checkbox
                    v-model="selected"
                    name="selected"
                    :value="{ id: data.item.id, address: data.item.address }"
                  />
                </template>
                <template #cell(picture)="data">
                  <img
                    v-if="data.item.picture"
                    :src="data.item.picture"
                    alt=""
                    class="cafes-item-thumb"
                  />
                </template>

                <template #cell(action)="data">
                  <router-link
                    :to="`/company/cafes/${data.item.id}`"
                    class="action-edit"
                    :title="$t('btn.edit')"
                    ><span class="material-symbols-rounded fz-24 v-middle mx-1">
                      edit
                    </span></router-link
                  >
                  <a
                    href="#"
                    @click.prevent="confirmDelete(data.item)"
                    class="action-delete"
                    :title="$t('btn.remove')"
                    ><span class="material-symbols-rounded fz-28 v-middle mx-1">
                      delete_forever
                    </span></a
                  >
                </template>

                <template #empty>
                  <h4 class="text-center">
                    {{ $t("table.no-data") }}
                  </h4>
                </template>
              </b-table>
            </div>
            <div class="row mb-3">
              <div class="col-12 text-center">
                <div class="dataTables_paginate paging_simple_numbers">
                  <b-pagination
                    v-model="page"
                    :total-rows="totalRows"
                    :per-page="per_page"
                  ></b-pagination>
                </div>
              </div>
            </div>

            <!-- <div><pre>tableFields: {{ tableFields }}</pre></div> -->
            <!-- <div>
              <pre>tableData: {{ tableData }}</pre>
            </div> -->
            <!-- <div>
              <pre>selected: {{ selected }}</pre>
            </div> -->
          </div>
        </div>
      </template>
    </div>
  </Layout>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useGlobalStore } from "@/store/global";
import Layout from "@/views/layouts/main";

export default {
  metaInfo() {
    return {
      title: this.$t("app.title") + " | " + this.$t("page.company.cafes"),
    };
  },
  components: {
    Layout,
  },
  data() {
    return {
      loading: false,
      error: "",
      wait: false,

      search: "",

      tableData: [],
      totalRows: 0,
      page: 1,
      pageOptions: [25, 50, 100],
      per_page: 25,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      isBusy: false,
      _helper: "",

      selected: [],
      selectedAll: false,

      tableFields: [
        {
          key: "select",
          // sortable: true,
          class: "cell-id",
          label: "",
        },
        {
          key: "picture",
        },
        {
          key: "address",
          sortable: true,
        },
        {
          key: "action",
          label: "",
          class: "cell-action",
        },
      ],
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    sortable() {
      return this.tableFields?.map((i) => i.sortable && i.key) || [];
    },
  },
  methods: {
    ...mapActions(useGlobalStore, [
      "getCafesList",
      "deleteCafes",
      "deleteCafesArr",
    ]),
    async setQuery(newQuery, toHistory = false) {
      const query = Object.assign({}, { ...this.$route.query, ...newQuery });

      // delete empty fields
      Object.keys(query).forEach((key) => {
        if (
          query[key] === undefined ||
          query[key] === null ||
          query[key] === ""
        ) {
          delete query[key];
        }
      });

      if (toHistory) {
        await this.$router.push({ query });
      } else {
        await this.$router.replace({ query }).catch((err) => {});
      }
    },
    paramFromQuery() {
      this.search =
        this.$route.query?.search?.length >= 3 ? this.$route.query.search : "";
      // this.block = this.$route.query?.block || "";
      this.per_page = Number(this.$route.query?.per_page) || 25;
      this.page = Number(this.$route.query?.page) || 1;

      this.sortBy = this.$route.query?.sort || "";
      if (this.sortBy) {
        if (this.$route.query?.sortd === "asc") {
          this.sortDesc = false;
        }
        if (this.$route.query?.sortd === "desc") {
          this.sortDesc = true;
        }
      }
    },
    async loadData() {
      try {
        this.loading = true;
        this.error = "";

        await this.loadTableData(false);
      } catch (error) {
        console.log("loadData Error: ", error);
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    async loadTableData(silent = true) {
      if (this.wait) {
        return;
      }

      this.wait = true;
      this.isBusy = true;

      this.paramFromQuery();

      try {
        if (!silent) {
          this.loading = true;
        }
        this.error = "";

        const params = {
          pages: this.per_page,
          page: this.page,
          search: this.search,
          sort: this.sortBy,
          toggle: "",
        };

        if (this.sortBy) {
          if (this.sortDesc) {
            params.toggle = "sorting%20sorting_desc";
          } else {
            params.toggle = "sorting%20sorting_asc";
          }
        }

        const req = await this.getCafesList(params);
        console.log("loadTableData, req: ", req);

        this.tableData = req.data.data || [];
        this.totalRows = req.data.total;
        this.selected = [];
        this.selectedAll = false;
      } catch (error) {
        console.log("loadTableData Error: ", error);
        this.error = error;

        this.error = this.$t("msg.error");

        if (error.response?.status && error.response?.data?.message) {
          this.error = `[${error.response.status}] ${error.response.data.message}`;
        }
      } finally {
        this.loading = false;
        this.wait = false;
        this.isBusy = false;
      }
    },
    confirmDelete(item) {
      this.$bvModal
        .msgBoxConfirm(
          this.$t("modal.delete-confirm-cafes", { cafes: item.address }),
          {
            title: this.$t("modal.delete-confirm"),
            size: "md",
            buttonSize: "md",
            okVariant: "danger",
            okTitle: this.$t("btn.yes"),
            cancelTitle: this.$t("btn.cancel"),
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (sure) => {
          if (sure) {
            await this.deleteCafes(item.id);
            this.loadTableData();
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    confirmDeleteArr() {
      let cafes = "";
      this.selected.map((item) => {
        cafes += `<br />${item.address}`;
      });

      const descr = this.$createElement("div", {
        domProps: {
          innerHTML: this.$t("modal.delete-confirm-cafes-selected", { cafes }),
        },
      });

      this.$bvModal
        .msgBoxConfirm(descr, {
          title: this.$t("modal.delete-confirm"),
          size: "md",
          buttonSize: "md",
          okVariant: "danger",
          okTitle: this.$t("btn.yes"),
          cancelTitle: this.$t("btn.cancel"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (sure) => {
          if (sure) {
            await this.deleteCafesArr(this.selected);
            await this.loadTableData();
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    searchStart() {
      if (this.search.length >= 3 || this.search === "") {
        this.setQuery({ page: 1, search: this.search }, true);
      }
    },
    searchReset() {
      this.search = "";
      this.setQuery({ page: 1, search: this.search }, true);
    },
    headClicked(column) {
      // console.log('headClicked, column: ', column)
      if (this.sortable.includes(column)) {
        // console.log('1 sortable...')

        if (column === this._helper) {
          if (this.sortDesc) {
            this.sortBy = "";
            this._helper = "";
          } else {
            this.sortBy = column;
            this._helper = column;
          }
        } else {
          this.sortDesc = false;
          this.sortBy = column;
          this._helper = column;
        }
      } else {
        // console.log('2 NOT sortable...')
        this.sortBy = "";
        this._helper = "";
      }
    },
    updateSort() {
      if (this.sortBy) {
        this.setQuery(
          { page: 1, sort: this.sortBy, sortd: this.sortDesc ? "desc" : "asc" },
          true
        );
      } else {
        this.setQuery({ page: 1, sort: "", sortd: "" }, true);
      }
    },
  },
  watch: {
    // watch query like nuxt watchQuery
    "$route.query"(q, old) {
      if (
        ((q.search || old.search) && q.search !== old.search) ||
        ((q.page || old.page) && q.page !== old.page) ||
        ((q.per_page || old.per_page) && q.per_page !== old.per_page) ||
        ((q.sort || old.sort) && q.sort !== old.sort) ||
        ((q.sortd || old.sortd) && q.sortd !== old.sortd)
      ) {
        this.loadTableData();
      }
    },
    page(page) {
      this.setQuery({ page }, true);
    },
    per_page(per_page) {
      this.setQuery({ page: 1, per_page }, true);
    },
    selectedAll(checked) {
      // console.log("onChangeAll... checked: ", checked);

      if (checked) {
        this.selected = this.tableData?.map((item) => {
          return {
            id: item.id,
            address: item.address,
          };
        });
      } else {
        this.selected = [];
      }
    },
    sortBy() {
      this.updateSort();
    },
    sortDesc() {
      this.updateSort();
    },
  },
};
</script>
<style scoped lang="scss">
.action-delete {
  color: #f1556c;
}

.draggable-box,
.draggable-box-list {
  display: contents;
}

.cafes-item-thumb {
  width: auto;
  height: 100%;
  max-height: 80px;
}
</style>
